<template>
  <b-card-actions
    ref="ProductsList"
    title="Produtos"
    action-refresh
    class="top-level"
    @refresh="updateRows({})"
  >
    <b-row align-v="start">

      <b-col md="4">
        <b-form-group>
          <label>Pesquisar</label>
          <b-form-input
            v-model="searchFilter"
            :disabled="state === 'loading'"
            placeholder="Pesquisar"
            type="text"
            class="d-inline-block"
            autocomplete="off"
            debounce="1000"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-row class="mt-1">
            <b-col
              cols="3"
              lg="2"
              class="mt-1 mt-lg-0 text-center"
              role="button"
              @click.stop="updatePublishMultiple(true, selected)"
            >
              <span style="font-size: 10px;">Publi.</span><br>
              <feather-icon
                icon="CheckSquareIcon"
                class="text-success"
                size="18"
              />
            </b-col>
            <b-col
              cols="3"
              lg="2"
              class="mt-1 mt-lg-0 text-center"
              role="button"
              @click.stop="updatePublishMultiple(false, selected)"
            >
              <span style="font-size: 10px;">Publi.</span><br>
              <feather-icon
                icon="XSquareIcon"
                class="text-danger"
                size="18"
              />
            </b-col>
            <b-col
              cols="3"
              lg="2"
              class="mt-1 mt-lg-0 text-center"
              role="button"
              @click.stop="updateSalesMultiple(true, selected)"
            >
              <span style="font-size: 10px;">Desc.</span><br>
              <feather-icon
                icon="CheckSquareIcon"
                class="text-success"
                size="18"
              />
            </b-col>
            <b-col
              cols="3"
              lg="2"
              class="mt-1 mt-lg-0 text-center"
              role="button"
              @click.stop="updateSalesMultiple(false, selected)"
            >
              <span style="font-size: 10px;">Desc.</span><br>
              <feather-icon
                icon="XSquareIcon"
                class="text-danger"
                size="18"
              />
            </b-col>
            <b-col
              cols="3"
              lg="2"
              class="mt-1 mt-lg-0 text-center"
              role="button"
              @click.stop="updateGoogle(selected)"
            >
              <span style="font-size: 10px;">Google</span><br>
              <feather-icon
                icon="ShoppingBagIcon"
                class="text-info"
                size="18"
              />
            </b-col>

          </b-row>
          <b-row class="my-1">
            <b-col>
              <b>{{ `${selected.length}` }}</b> produtos selecionados
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="ml-1">
      <b-col>
        <b>{{ $store.state.products.productsList.length }}</b> de <b>{{ $store.state.products.totalCount }}</b> Produtos
      </b-col>
      <b-col>
        <b-form-group
          label="Selecionar tudo"
          label-cols="9"
        >
          <b-form-checkbox
            v-model="allSelected"
            class="mr-0 mt-50"
            name="all-selected"
            switch
            inline
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showAvisos = !showAvisos"
        >
          Lista de Avisos
        </b-button>
      </b-col>
      <b-col>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="downloadProdutos()"
        >
          Excel Produtos
        </b-button>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          class="float-right"
          variant="success"
          :to="{ name : 'product-create'}"
          :disabled="state === 'loading'"
        >
          Novo produto
        </b-button>
      </b-col>
    </b-row>

    <div>
      <b-card
        v-for="product in rows"
        :key="product.id"
        :class="`mb-1 list-row ${selected.includes(product.id) ? 'row-selected' : ''} ${product.quantity < 0 ? 'negative-stock' : ''}`"
        @click="selectCard(product.id)"
      >
        <b-row align-v="center">
          <b-col
            lg="6"
            cols="12"
          >
            <b-row align-v="center">
              <b-col
                cols="1"
                class="px-0"
                @click.stop="() => {}"
              >
                <input
                  v-model="selected"
                  type="checkbox"
                  name=""
                  :value="product.id"
                >
              </b-col>
              <b-col
                cols="11"
              >
                <span
                  class="text-nowrap flex-parent"
                >
                  <b-avatar
                    :src="product.thumbnail_url"
                    class="mx-1"
                  />
                  <span class="flex-child long-and-truncated">{{ product.name }}</span>
                </span>
                <b-button
                  v-if="product.notify > 0 && product.quantity > 0"
                  size="sm"
                  variant="primary"
                  class="ml-1"
                  @click.stop="sendNotif(product.id)"
                >
                  Avisar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            lg="6"
            cols="12"
            class="d-lg-block"
          >
            <b-row align-v="center">
              <!-- <b-col cols="4" lg="2" class="mt-1 mt-lg-0 text-center"
                v-b-popover.hover.top="'Quantidade'"
              > -->
              <b-col
                cols="4"
                lg="2"
                class="mt-1 mt-lg-0 text-center"
              >
                <span style="font-size: 10px;">Stock</span><br>
                {{ product.quantity }}
              </b-col>
              <b-col
                v-b-popover.hover.top="product.published ? 'Esconder' : 'Publicar'"
                cols="4"
                lg="2"
                class="mt-1 mt-lg-0 text-center"
                role="button"
                @click.stop="updatePublish(!product.published, product.id)"
              >
                <span style="font-size: 10px;">Publi.</span><br>
                <feather-icon
                  v-if="product.published"
                  icon="CheckSquareIcon"
                  class="text-success"
                  size="18"
                />
                <feather-icon
                  v-else
                  icon="XSquareIcon"
                  class="text-danger"
                  size="18"
                />
              </b-col>
              <b-col
                v-b-popover.hover.top="product.sale ? 'Desativar' : 'Ativar'"
                cols="4"
                lg="2"
                class="mt-1 mt-lg-0 text-center"
                role="button"
                @click.stop="updateSales(!product.sale, product.id)"
              >
                <span style="font-size: 10px;">Desc.</span><br>
                <feather-icon
                  v-if="product.sale"
                  icon="CheckSquareIcon"
                  class="text-success"
                  size="18"
                />
                <feather-icon
                  v-else
                  icon="XSquareIcon"
                  class="text-danger"
                  size="18"
                />
              </b-col>
              <b-col
                v-if="product.hasOwnProperty('featured')"
                v-b-popover.hover.top="product.featured ? 'Desativar' : 'Ativar'"
                cols="4"
                lg="2"
                class="mt-1 mt-lg-0 text-center"
                role="button"
                @click.stop="updateDestaque(!product.featured, product.id)"
              >
                <span style="font-size: 10px;">Destaque</span><br>
                <feather-icon
                  v-if="product.featured"
                  icon="CheckSquareIcon"
                  class="text-success"
                  size="18"
                />
                <feather-icon
                  v-else
                  icon="XSquareIcon"
                  class="text-danger"
                  size="18"
                />
              </b-col>
              <b-col
                cols="6"
                lg="4"
              >
                <b-row>
                  <b-col
                    cols="6"
                    class="mt-1 mt-lg-0 text-center text-lg-left"
                  >
                    <span style="font-size: 10px;">Preço.</span><br>
                    {{ product.price }}
                  </b-col>
                  <b-col
                    v-if="selected.length <= 1"
                    cols="6"
                    class="mt-1 mt-lg-0 text-center text-lg-left"
                  >
                    <span style="font-size: 10px;">Promo.</span><br>
                    {{ product.discounted_price }}
                  </b-col>
                  <b-col
                    v-else
                    cols="6"
                    class="mt-0 text-center"
                  >

                    <b-button
                      variant="success"
                      @click.stop="() => { selected = [product.id]}"
                    ><feather-icon icon="ArrowRightIcon" /></b-button>
                  </b-col>
                </b-row>

              </b-col>

            </b-row>
          </b-col>
        </b-row>
        <b-row
          v-if="selected.includes(product.id) && selected.length === 1"
          align-v="center"
          class="pb-1"
        >
          <b-col
            lg="2"
            cols="12"
          >
            <b-button
              :to="{ name: 'product-edit', params: { id: product.id } }"
              variant="success"
              :disabled="state === 'loading'"
              class="mt-1"
            ><feather-icon icon="EditIcon" /> Editar</b-button>
          </b-col>
          <b-col
            cols="12"
            lg="2"
            class="mt-0 text-center"
            @click.stop="() => {}"
          >
            <b-form-group
              label="Quantidade"
              label-for="quantity"
            >
              <b-form-input
                id="quantity"
                v-model="quantity"
                :disabled="state === 'loading'"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="2"
            cols="12"
            @click.stop="() => {}"
          >
            <b-form-group>
              <label
                for="datepicker-end"
              >Data</label>
              <b-form-datepicker
                id="datepicker-end"
                v-model="stockDate"
                placeholder="Data"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                reset-button
                locale="pt-br"
              />
            </b-form-group>
          </b-col>
          <b-col
            lg="3"
            cols="12"
            @click.stop="() => {}"
          >
            <label
              for="reason"
            >Razão</label>
            <v-select
              id="reason"
              v-model="stockReason"
              label="text"
              class="bg-white"
              :options="stockReasonChoices"
              :close-on-selec="true"
              :reduce="c => c.value"
              :clearable="false"
              :disabled="state === 'loading'"
            >
              <template #no-options="{}">
                A lista de opções está vazia
              </template>
            </v-select>
          </b-col>
          <b-col
            lg="2"
            cols="12"
          >
            <b-button
              variant="success"
              :disabled="state === 'loading'"
              class="mt-1"
              @click.stop="updateStock(product.id)"
            >Validar Estoque</b-button>
          </b-col>
          <b-col
            lg="1"
            cols="12"
          >
            <b-button
              variant="success"
              :disabled="state === 'loading'"
              class="mt-1"
              @click.stop="duplicate(product.id)"
            ><feather-icon icon="CopyIcon" /></b-button>
          </b-col>
        </b-row>
        <b-row
          v-if="selected.includes(product.id) && selected.length === 1"
          align-v="center"
          class="pb-1"
        >
          <b-col
            cols="12"
            lg="2"
          >
            <span style="font-size: 10px;">Google Shopping</span><br>
            <b-button
              variant="success"
              :disabled="state === 'loading'"
              @click.stop="updateGoogle([product.id])"
            ><feather-icon icon="RefreshCwIcon" />
              Sincronizar</b-button>

          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="d-flex justify-content-between flex-wrap mb-1">
      <div class="d-flex align-items-center mb-0 mt-1">
        <span class="text-nowrap">
          Tamanho da página
        </span>
        <b-form-select
          v-model="$store.state.products.filters.pageSize"
          :disabled="state === 'loading'"
          :options="['1','5','10', '50', '100', '250']"
          class="mx-1"
          @input="(value)=>updateRows({ pageSize: value })"
        />
      </div>
      <div>
        <b-pagination
          v-model="$store.state.products.filters.page"
          :disabled="state === 'loading'"
          :total-rows="$store.state.products.totalCount"
          :per-page="$store.state.products.filters.pageSize"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value)=>updateRows({ page: value })"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
    <b-modal
      id="modal-scrollable"
      v-model="showAvisos"
      scrollable
      title="Avisos"
      cancel-title="Close"
      ok-title="Accept"
      cancel-variant="outline-secondary"
    >
      <dl
        v-for="item in $store.state.orders.avisos"
        :key="`${item.email}-${item.product}`"
        style="border-bottom: 1px solid #ccc; padding-bottom: 10px;"
        class="row"
      >
        <dt class="col-6">
          {{ item.email }}
        </dt>
        <dd class="col-6">
          {{ item.product_name }}
        </dd>
      </dl>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="showAvisos=false"
          >
            Fechar
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker, BModal,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      showAvisos: false,
      stockReasonChoices: [
        {
          value: 'STOCK_UP',
          text: 'Novo estoque',
        },
        {
          value: 'LOST',
          text: 'Perda',
        },
        {
          value: 'MANUTENTION',
          text: 'Manutenção',
        },
        {
          value: 'EXCHANGE',
          text: 'Troca',
        },
        {
          value: 'GIFT',
          text: 'Doação',
        },
        {
          value: 'OTHER',
          text: 'Outro',
        },
      ],
      stockReason: 'STOCK_UP',
      quantity: 1,
      allSelected: false,
      allSelectState: false,
      selected: [],
      searchFilter: null,
      state: 'ready',
      dir: false,
      columns: [
        {
          label: 'Nome',
          field: 'name',
        },
        {
          label: 'Quantidade disponível',
          field: 'quantity',
        },
        {
          label: 'Publicado',
          field: 'published',
        },
        {
          label: 'Promoção',
          field: 'sale',
        },
        {
          label: 'Preço',
          field: 'price',
        },
        {
          label: 'Preço com desconto',
          field: 'discounted_price',
        },
      ],
    }
  },
  computed: {
    rows() {
      return this.$store.state.products.productsList
    },
  },
  watch: {
    allSelected(value) {
      if (value) {
        this.selected = this.$store.state.products.productsList.map(item => item.id)
        this.allSelectState = false
      } else if (this.allSelectState) {
        this.allSelectState = false
      } else {
        this.selected = []
      }
    },
    selected(value) {
      if (value.length !== this.$store.state.products.productsList.length) {
        this.allSelectState = true
        this.allSelected = false
      }
    },
    searchFilter(val) {
      this.pesquisa()
    },
  },
  created() {
    this.$store.dispatch('orders/getAvisos')
    this.stockDate = (new Date()).toISOString().substring(0, 10)
    this.searchFilter = this.$store.state.products.filters.search ? this.$store.state.products.filters.search : ''
  },
  methods: {
    downloadProdutos() {
      this.$store.dispatch('products/downloadProducts').then(response => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `produtos_${Date.now()}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Não conseguimos baixar o arquivo',
            icon: 'UserIcon',
            variant: 'danger',
          },
        })
      })
    },
    selectCard(id, unique = true) {
      const index = this.selected.indexOf(id)
      if (unique) {
        if (index > -1) {
          this.selected = []
        } else {
          this.selected = [id]
        }
      } else if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }
    },
    pesquisa() {
      const tmp = this.searchFilter
      this.state = 'loading'
      this.$store.dispatch('products/getProducts', {
        search: tmp,
        page: 1,
      }).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.products.productsList.find(product => product.id === item))
        this.state = 'ready'
      })
    },
    updateRows(filters) {
      this.state = 'loading'
      this.$refs.ProductsList.showLoading = true
      this.$store.dispatch('products/getProducts', filters).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.products.productsList.find(product => product.id === item))
        this.state = 'ready'
        this.$refs.ProductsList.showLoading = false
      })
    },
    updatePublish(status, productId) {
      // const index = this.selected.indexOf(productId);
      // if (index < 0) {
      //   this.selected.push(productId)
      // }
      this.state = 'loading'
      this.$refs.ProductsList.showLoading = true
      this.$store.dispatch('products/updatePublish', { status, productIds: [productId] }).then(() => {
        this.updateRows()
      }).catch(() => {
        this.state = 'ready'
        this.$refs.ProductsList.showLoading = false
      })
    },
    updatePublishMultiple(status, productIds) {
      this.state = 'loading'
      this.$refs.ProductsList.showLoading = true
      this.$store.dispatch('products/updatePublish', { status, productIds }).then(() => {
        this.updateRows()
      }).catch(() => {
        this.state = 'ready'
        this.$refs.ProductsList.showLoading = false
      })
    },
    updateSales(status, productId) {
      this.state = 'loading'
      this.$refs.ProductsList.showLoading = true
      this.$store.dispatch('products/updateSales', { status, productIds: [productId] }).then(() => {
        this.updateRows()
      }).catch(() => {
        this.state = 'ready'
        this.$refs.ProductsList.showLoading = false
      })
    },
    updateDestaque(status, productId) {
      this.state = 'loading'
      this.$refs.ProductsList.showLoading = true
      this.$store.dispatch('products/updateDestaque', { status, productIds: [productId] }).then(() => {
        this.updateRows()
      }).catch(() => {
        this.state = 'ready'
        this.$refs.ProductsList.showLoading = false
      })
    },
    updateSalesMultiple(status, productIds) {
      this.state = 'loading'
      this.$refs.ProductsList.showLoading = true
      this.$store.dispatch('products/updateSales', { status, productIds }).then(() => {
        this.updateRows()
      }).catch(() => {
        this.state = 'ready'
        this.$refs.ProductsList.showLoading = false
      })
    },
    sendNotif(id) {
      this.state = 'loading'
      this.$store.dispatch('products/sendNotification', id).then(
        result => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Avisos enviados para ${result.sent} pessoas.`,
              icon: 'UserIcon',
              variant: 'success',
            },
          })
          this.$store.dispatch('products/getProducts').then(() => {
            this.rows = this.$store.state.products.productsList
          }).finally(() => {
            this.state = 'ready'
          })
        },
      ).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Não conseguimos enviar os avisos',
            icon: 'UserIcon',
            variant: 'danger',
          },
        })
        this.state = 'ready'
      })
    },
    duplicate(id) {
      this.state = 'loading'
      this.$store.dispatch('products/duplicateProducts', [id]).then(
        result => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Duplicado.',
              icon: 'PlusIcon',
              variant: 'success',
            },
          })
          this.$store.dispatch('products/getProducts').then(() => {
            this.rows = this.$store.state.products.productsList
          })
        },
      ).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Não conseguimos duplicar',
            icon: 'PlusIcon',
            variant: 'danger',
          },
        })
        this.state = 'ready'
      }).finally(() => {
        this.state = 'ready'
      })
    },
    updateStock(id) {
      this.state = 'loading'
      this.$store.dispatch('products/updateStock', {
        productId: id,
        date: this.stockDate,
        reason: this.stockReason,
        quantity: this.quantity,
      }).then(
        result => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Estoque atualizado.',
              icon: 'PlusIcon',
              variant: 'success',
            },
          })
          this.$store.dispatch('products/getProducts').then(() => {
            this.rows = this.$store.state.products.productsList
          }).finally(() => {
            this.state = 'ready'
            this.stockDate = (new Date()).toISOString().substring(0, 10)
            this.stockReason = 'STOCK_UP'
            this.quantity = 1
          })
        },
      ).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Não conseguimos atualizar o estoque',
            icon: 'PlusIcon',
            variant: 'danger',
          },
        })
        this.state = 'ready'
      })
    },
    updateGoogle(idsList) {
      this.state = 'loading'
      this.$store.dispatch('products/updateGoogle', idsList).then(
        result => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Google atualizado.',
              icon: 'PlusIcon',
              variant: 'success',
            },
          })
        },
      ).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Não conseguimos atualizar o google',
            icon: 'PlusIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.state = 'ready'
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card-body{
  padding-top: 0;
  padding-bottom: 0;
}

.list-row:hover {
  background: rgb(226, 226, 226);
}
.row-selected {
  background: #004259 !important;
  color: white;
}

.negative-stock {
  background: rgb(116, 0, 0) !important;
  color: white !important;
}

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#reason{
  border-radius: 6px;
}
</style>
